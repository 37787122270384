@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: block;
  width: 100%;

  @media (--viewportMedium) {
    padding-block-start: 16px;
    padding-block-end: 0;
    border-block-end: 0;
  }
}

.filterHeader {
  line-height: 24px;
}
.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightBold);

  /* Baseline adjustment for label text */
  margin-block-start: 0;
  margin-block-end: 12px;
  padding-block-start: 4px;
  padding-inline-end: 0;
  padding-block-end: 2px;
  padding-inline-start: 0;

  @media (--viewportMedium) {
    padding: 0;
    margin-block-end: 8px;
  }
}

.label {
  color: var(--colorGrey700);
}

.labelSelected {
  color: var(--marketplaceColor);
  white-space: nowrap;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
}

.openSign {
  float: inline-end;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-block-start: 7px;
  background-color: var(--colorWhite);

  /* Borders */
  border-block-start: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding-block-start: 15px;
  padding-inline-end: 30px;
  padding-block-end: 17px;
  padding-inline-start: 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
