@import '../../../styles/customMediaQueries.css';
.root {
  outline: none;
}

.contentWrapper {
  margin-block-end: 0;
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}

.clearButton,
.cancelButton,
.submitButton {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;
  @media (--viewportMedium) {
    font-size: 18px;
  }

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin-block-start: 0;
  margin-inline-end: auto;
  margin-block-end: 0;
  margin-inline-start: 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-inline-start: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-block-end: 0;
  margin-inline-start: 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
