@import '../../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  margin-block-start: 7px;
  background-color: var(--colorWhite);

  /* Borders */
  border-block-start: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);
  padding-block-start: 15px;
  padding-inline-end: 30px;
  padding-block-end: 20px;
  padding-inline-start: 30px;
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;

  @media (--viewportMedium) {
    padding-block-start: 8px;
    padding-block-end: 16px;
  }
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  padding-block-start: 5px;
  padding-block-end: 5px;
  margin-block-start: 0px;
}
@media (--viewportMedium) {
  .contentWrapper {
    padding-block-start: 0px;
    padding-block-end: 8px;
    margin-block-start: 0px;
  }
}

.label {
  composes: h5 from global;

  font-weight: var(--fontWeightSemiBold);
  padding-block-start: 11px;
  padding-inline-end: 0;
  padding-block-end: 13px;
  padding-inline-start: 0;

  margin-block-start: 0;
  margin-inline-end: 18px;
  margin-block-end: 0;
  margin-inline-start: 0;
}
@media (--viewportMedium) {
  .label {
    padding-block-start: 8px;
    padding-inline-end: 0;
    padding-block-end: 16px;
    padding-inline-start: 0;
  }
}

.inputsWrapper {
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    padding-block-end: 5px;
  }
}

.minPrice,
.maxPrice {
  width: 100%;
  text-align: center;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding-block-start: 3px;
    padding-inline-end: 0;
    padding-block-end: 3px;
    padding-inline-start: 0;
  }
}

.priceInSidebar {
  width: 72px;
}

.priceSeparator {
  margin-block-start: 6px;
  margin-inline-end: 8px;
  margin-block-end: 0;
  margin-inline-start: 8px;

  @media (--viewportMedium) {
    margin-block-start: 5px;
    margin-inline-end: 8px;
    margin-block-end: 0;
    margin-inline-start: 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding-block-start: 17px;
  padding-inline-end: 0;
  padding-block-end: 25px;
  padding-inline-start: 0;
}
@media (--viewportMedium) {
  .sliderWrapper {
    padding-block-start: 3px;
    padding-inline-end: 0;
    padding-block-end: 24px;
    padding-inline-start: 0;
  }
}

.buttonsWrapper {
  display: flex;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin-block-start: 0;
  margin-inline-end: auto;
  margin-block-end: 0;
  margin-inline-start: 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-inline-start: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-block-end: 0;
  margin-inline-start: 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
