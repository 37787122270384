@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-block-start: 24px;
  padding-block-end: 17px;
  border-block-end: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    padding-block-start: 16px;
    padding-block-end: 0;
    border-block-end: 0;
  }
}

.filterHeader {
  line-height: 24px;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: start;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightBold);

  /* Baseline adjustment for label text */
  margin-block-start: 0;
  margin-block-end: 12px;

  @media (--viewportMedium) {
    margin-block-end: 8px;
  }
}

.label {
  color: var(--colorGrey700);
}

.labelSelected {
  color: var(--marketplaceColor);
  margin-inline-start: 5px;
}

.openSign {
  float: inline-end;
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  padding-block-start: 2px;
  padding-inline-end: 0;
  padding-block-end: 4px;
  padding-inline-start: 12px;
  /* Override button styles */
  outline: none;
  text-align: start;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin-block-start: 8px;
    padding-block-start: 4px;
    padding-inline-end: 0;
    padding-block-end: 4px;
    padding-inline-start: 12px;
  }
}

.plain {
  width: 100%;
  display: none;

  @media (--viewportMedium) {
    padding-block-end: 16px;
  }
}

.isOpen {
  display: block;
}
