@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-inline-start: 12px;
  padding-block-end: 6px;
  border: none;

  @media (--viewportMedium) {
    padding-block-start: 4px;
    padding-inline-end: 0;
    padding-block-end: 4px;
    padding-inline-start: 12px;
  }
}

.list {
  margin: 0;
}

.item {
  padding-block-start: 4px;
  padding-inline-end: 0;
  padding-block-end: 2px;
  padding-inline-start: 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
